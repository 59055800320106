import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from "../components/seo"
import BreadcrumbModule from '../components/BreadcrumbModule/BreadcrumbModule'
import StaticIntro from '../components/StaticIntro/StaticIntro'
import CtaModule from '../components/CtaModule/CtaModule'
import StaticDesc from '../components/StaticDesc/StaticDesc'


const StaticTemplate = ({ data }, props) => {
    const PageData = data?.strapiPage
    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule
                subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
                subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
                parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title}
                parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
                title={PageData.title}
            />
            <StaticIntro title={PageData.title} staticClass="static-page-intro" />

            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.plain-content" && <StaticDesc description={module?.content?.data?.content} />}
                    </>
                )
            })}

            <CtaModule />
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default StaticTemplate

export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
              ...ImageAndContentFragment
            }
        }
    }
  }
`