import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import './assets/styles/_index.scss';
import { PageLinks } from "../../common/site/page-static-links";

const CtaModule = (props) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    content {
                        footer_cta_module {
                            title
                            description
                            cta_1_title
                            cta_1_link
                            cta_2_title
                            cta_2_link
                        }
                    }
                }
            }
        }
    `)

    const content = site?.siteMetadata?.content?.footer_cta_module
    return (
        <section className={`cta-module-wrapper ${props.tag}`}>
            <Container>
                <Row className={`${props.tag === "property-results" ? "d-flex align-items-center" : ""}`}>
                    <Col xl={7} className={`${props.tag === "property-results" ? "d-flex justify-content-xl-end justify-content-md-center" : ""}`}>
                        <ScrollAnimation animateIn="animate__fadeIn" delay={100} animateOnce offset={50}>
                            <div className="cta-module-title">{content.title}</div>
                            {
                                props.tag === "property-results" ? "" :
                                    <div className="cta-module-text d-xl-block d-none">{content.description}</div>
                            }
                        </ScrollAnimation>
                    </Col>
                    <Col xl={5} className={`${props.tag === "property-results" ? "d-flex justify-content-xl-start justify-content-center" : "d-flex justify-content-xl-end justify-content-center"}`}>
                        <ScrollAnimation animateIn="animate__fadeIn" delay={100} animateOnce offset={50}>
                            <ul className="list-inline cta-module-list">
                                <li className="list-inline-item">
                                    <Link to={`/${content.cta_1_link}/`} className="button button-primary-outline-transparent">{content.cta_1_title}</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to={`/${content.cta_2_link}/`} className="button button-primary-outline-transparent">{content.cta_2_title}</Link>
                                </li>
                            </ul>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CtaModule