import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll'
import './styles/_index.scss'

const StaticDesc = (props) => {
    return (
        <div className='static-desc-wrapper'>
            <Container>
                <Row>
                    <Col xl={7}>
                        <ScrollAnimation animateIn="animate__slideInUp" delay={300} animateOnce offset={10}>
                            <div className='static-desc-content' dangerouslySetInnerHTML={{ __html: props.description }} />
                        </ScrollAnimation>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={4} className="d-xl-block d-none"></Col>
                </Row>
            </Container>
        </div>
    )
}

export default StaticDesc